import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="flex px-4 py-4 justify-between items-center bg-gray-100">
      <Link to="/">
        <h1 className="text-4xl font-bold  text-gray-500" src="/">
          <span className="text-orange-500">N</span>evio
        </h1>
      </Link>
    </nav>
  );
};
export default Navbar;
