const CardContainer = ({ children }) => (
  <div className="flex flex-wrap justify-center px-4 sm:px-8 xl:px-4">
    {children}
  </div>
);

export default CardContainer;

/**
 * EXAMPLE
 * <CardContainer>
            <CardWithCTA
              title="Android Native"
              description="Get early access to all the newest features integrated with your Android Phone"
              buttonText="Sign up"
              onClick={() => console.log("click")}
            />
          </CardContainer>
 * 
 */
