import { Link } from "react-router-dom";
import Hero from "../components/Hero";

import androidImage from "../img/apple-iphone-15-black-mockup/nevio-android-screenshot-portrait.png";

const HomePage = () => {
  const userAgent = navigator.userAgent;

  return (
    <div className="">
      <div className="w-screen">
        <Hero />
      </div>
      <div
        id="download"
        className=" bg-slate-100 dark:bg-gray-700 text-gray-500 dark:text-white"
      >
        <div
          className={`container px-4 sm:px-8 lg:grid lg:grid-cols-2 ${
            userAgent.includes("Android") ? "hidden" : ""
          }`}
        >
          <div className="w-1/2 m-auto mb-16 lg:mb-0">
            <img src={androidImage} alt="alternative" />
          </div>
          <div className="lg:mt-24 xl:mt-44 xl:ml-12">
            <p className="mb-9 text-gray-500  dark:text-white text-3xl leading-10">
              Do you have an Android device? Join our early access program
            </p>
            <Link to="/android-early-access">
              <button
                className="inline-flex items-center px-3 py-2 text-sm font-medium
              text-center text-white bg-orange-500 rounded-lg hover:bg-blue-800
              focus:ring-4 focus:outline-none focus:ring-blue-300
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <span className="text-2xl">Find out more</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
