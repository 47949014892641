import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

import HomePage from "./pages/HomePage";
import AndroidEarlyAccessPage from "./pages/AndroidEarlyAccessPage";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/navigation/Footer";

import bgSm from "./img/bg-2-sm.jpg";

const App = () => {
  const appDescription =
    "Step into a world where every location reveals its secrets. With Nevio, discover rich histories, hidden insights, and poetic narrations that bring each place to life, making your journey truly unforgettable.";

  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nevio Audioguide</title>
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>⛰️</text></svg>"
        />
        <link rel="canonical" href="https://nevio.app" />
        <meta name="description" content={appDescription} />
        <meta name="twitter:card" content="product" />
        <meta name="twitter:site" content="@ugobriasco" />
        <meta name="twitter:title" content="Nevio Audioguide" />
        <meta name="twitter:description" content={appDescription} />
        <meta name="twitter:creator" content="@ugobriasco" />
        <meta name="twitter:image" content={bgSm} />
        <meta property="og:title" content="Nevio Audioguide" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://nevio.app" />
        <meta property="og:image" content={bgSm} />
        <meta property="og:description" content={appDescription} />
        <meta property="og:site_name" content="Nevio"></meta>
      </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route
          path="/android-early-access"
          element={<AndroidEarlyAccessPage />}
        ></Route>
        <Route
          path="*"
          element={
            <main className="m-20 p-20 text-center  text-orange-400">
              <h1 className="text-6xl mb-8">404</h1>
              <p>
                Noting to see here yet: something will pop up soon, thus stay
                tuned!
              </p>
            </main>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
