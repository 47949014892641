const Footer = () => (
  <footer className="bg-slate-100 dark:bg-gray-700 text-gray-500 dark:text-white">
    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="flex-1 text-sm text-center text-gray-500 sm:text-center dark:text-gray-400">
        © 2016-24{" "}
        <a href="https://nevio.app/" className="hover:underline">
          Nevio Audioguide
        </a>
      </span>
    </div>
  </footer>
);

export default Footer;
