const CardWithCTA = ({ title, description, buttonText, onClick }) => {
  return (
    <div className="max-w-sm m-4 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-500 dark:text-white">
          {title || "MY TITLE"}
        </h5>
      </div>
      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
        {description || "MY DESCRIPTION"}
      </p>
      <button
        onClick={onClick}
        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-orange-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {buttonText || "Click here"}
      </button>
    </div>
  );
};

export default CardWithCTA;
