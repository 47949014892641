import { useLayoutEffect } from "react";
import CardWithCTA from "../components/cards/CardWithCTA";
import CardContainer from "../components/containers/CardContainer";

const AndroidEarlyAccessPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="w-screen sm:h-min-screen md:h-screen bg-slate-100 dark:bg-gray-700 text-gray-500 dark:text-white">
      <div className="container mx-auto h-full sm:p-10">
        <h1 className="py-5 text-4xl md:text-6xl font-bold text-center">
          Android Early Access
        </h1>
        <div className="lg:mt-20">
          <CardContainer>
            <CardWithCTA
              title="Join the Alpha program"
              description="To enhance the user experience, we're currently developing the native Android version of our app. Join our Alpha program for early access."
              buttonText="Signup on Firebase"
              onClick={() =>
                window.open(
                  "https://appdistribution.firebase.dev/i/04dae30778877d54",
                  "_blank"
                )
              }
            />
            <CardWithCTA
              title="Join our community"
              description="Nevio is a community-driven application.
We value your input! New features will be introduced based on the community's votes and feedback."
              buttonText="Join our Discord"
              onClick={() =>
                window.open("https://discord.gg/WskTNyjmc5", "_blank")
              }
            />
          </CardContainer>
        </div>
      </div>
    </div>
  );
};

export default AndroidEarlyAccessPage;
