import bgPanorama from "../img/bg-2.jpg";
import bgAndroidAPK from "../img/nevio-android-screenshot.png";
//src="https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"

const Hero = () => {
  // here I want to detect if the client is an android device so that we can push the alpha APK
  const userAgent = navigator.userAgent;
  console.log(userAgent);

  const bg = userAgent.includes("Android") ? bgAndroidAPK : bgPanorama;
  const ctaURL = userAgent.includes("Android")
    ? "/android-early-access"
    : "https://web.nevio.app";

  return (
    <div className="flex flex-wrap h-screen bg-slate-100 dark:bg-gray-700 text-gray-500 dark:text-white">
      <div className="w-full sm:w-8/12 mb-10">
        <div className="container mx-auto h-full sm:p-10">
          {/* <nav className="flex px-4 justify-between items-center bg-red-100">
            <h1 className="text-4xl font-bold">
              <span className="text-orange-500">N</span>evio
            </h1>
            <div>
              <img
                src="https://image.flaticon.com/icons/svg/497/497348.svg"
                alt=""
                className="w-8"
              />
            </div>
          </nav> */}
          <header className="container px-4 lg:flex mt-10 items-center h-full lg:mt-0">
            <div className="w-full">
              <h1 className="text-4xl lg:text-6xl font-bold">
                Unlock <span className="text-orange-500">stories</span> around
                you
              </h1>
              <div className="w-20 h-2 bg-orange-500 my-4"></div>
              <p className="text-xl mb-10">
                Step into a world where every location reveals its secrets. With
                Nevio, discover rich histories, hidden insights, and poetic
                narrations that bring each place to life, making your journey
                truly unforgettable.
              </p>
              <button
                className="inline-flex items-center px-3 py-2 font-medium
              text-center text-2xl text-white bg-orange-500 rounded-lg hover:bg-blue-800
              focus:ring-4 focus:outline-none focus:ring-blue-300
              dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => window.open(ctaURL, "_blank")}
              >
                Get started
              </button>
            </div>
          </header>
        </div>
      </div>
      <img
        alt="some amazing stuff"
        src={bg}
        className="top-0 right-0 w-full h-48 object-cover lg:absolute sm:h-screen sm:w-4/12"
      />
    </div>
  );
};

export default Hero;
